<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      User
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>

                  <v-dialog v-model="dialog" max-width="80%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        dark
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-bold
                          text-capitalize
                          ms-auto
                          btn-primary
                          bg-success
                          py-3
                          px-6
                          ms-3
                        "
                        >New User</v-btn
                      >
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>
                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-form ref="frm">
                            <v-row>
                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >First name</label
                                >

                                <v-text-field
                                  v-model="editedItem.first_name"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter First name"
                                  :rules="[
                                    (v) => !!v || 'First name is required',
                                  ]"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Last name</label
                                >

                                <v-text-field
                                  v-model="editedItem.last_name"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Last name"
                                  :rules="[
                                    (v) => !!v || 'Last name is required',
                                  ]"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  ><span class="red--text"
                                    ><strong>* </strong></span
                                  >Date of birth</label
                                >

                                <v-menu
                                  ref="dob_menu"
                                  v-model="dob_menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="dob_menu"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.dob"
                                      v-bind="attrs"
                                      v-on="on"
                                      hide-details="auto"
                                      class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        input-icon
                                        mt-2
                                      "
                                      dense
                                      flat
                                      filled
                                      solo
                                      height="43"
                                      placeholder="Date of birth"
                                      persistent-hint
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    color="green lighten-1"
                                    header-color="primary"
                                    v-model="editedItem.dob"
                                    :max="dob_validation"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="dob_menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs.dob_menu.save(editedItem.dob)
                                      "
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Phone</label
                                >

                                <v-text-field
                                  v-model="editedItem.phone"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Phone"
                                  :rules="[(v) => !!v || 'Phone is required']"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Email</label
                                >

                                <v-text-field
                                  v-model="editedItem.email"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Email"
                                  :rules="[(v) => !!v || 'Email is required']"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Role</label
                                >

                                <v-select
                                  v-model="editedItem.role"
                                  :items="role"
                                  item-text="role"
                                  item-value="id"
                                  placeholder="Select Role"
                                  :rules="[(v) => !!v || 'Role is required']"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    select-style
                                    mt-2
                                  "
                                  outlined
                                  height="43"
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-text
                                      name="ss_name"
                                      label
                                      color="bg-default"
                                      class="py-1 px-2 my-0"
                                    >
                                      <span class="text-caption ls-0">{{
                                        item.role
                                      }}</span>
                                    </v-text>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Username</label
                                >

                                <v-text-field
                                  v-model="editedItem.user_name"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Username"
                                  :rules="[
                                    (v) => !!v || 'Username is required',
                                  ]"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                xs="12"
                                lg="3"
                                v-if="editedIndex === -1"
                              >
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Password</label
                                >

                                <v-text-field
                                  v-model="editedItem.password"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Password"
                                  :rules="passwordRules"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                xs="12"
                                lg="3"
                                v-else
                              >
                                <v-btn
                                  @click="changePasswordDialog = true"
                                  color="green darken-1 "
                                  class="mt-7"
                                  text
                                  >change Password</v-btn
                                ></v-col
                              >
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            py-3
                            px-6
                          "
                          >Cancel</v-btn
                        >

                        <v-btn
                          @click="save"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          dark
                          class="
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          "
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.dob`]="{ item }">
                    <span>
                      {{ formatDate(item.dob) }}
                    </span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <!-- Change Password Dialogue -->
      <v-dialog v-model="changePasswordDialog" max-width="600">
        <v-card>
          <v-card-title class="text-h5"> Change Password? </v-card-title>

          <v-card-text class="mt-5">
            <v-form ref="frm_password">
              <v-row>
                <v-col>
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >New Password</label
                  >

                  <v-text-field
                    v-model="newPassword"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="New Password "
                    :rules="passwordRules"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Confirm Password</label
                  >

                  <v-text-field
                    v-model="confirmPassword"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Confirm Password"
                    :rules="passwordRules"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="changePassword">
              change
            </v-btn>

            <v-btn color="green darken-1" text @click="dialogClose">
              cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import jwt_decode from "jwt-decode";
import api from "../api";
import { paginationLimits } from "../../../../global-data/paginationLimits";
import { format, parseISO } from "date-fns";
export default {
  // name: "paginated-tables",
  data() {
    return {
      //    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => /([a-z])/.test(v) || `Atleast one Lower Case letter`,
        (v) => /([A-Z])/.test(v) || `Atleast one Upper Case letter`,
        (v) => /([0-9])/.test(v) || `Atleast one digit`,
        (v) =>
          /([$&+,:;=?@#|'<>.^*()%!-])/.test(v) ||
          `Atleast One Special Character`,
        (v) => v.length > 8 || "Be Atleast 8 characters",
      ],
      pwd_regx: "",

      userToken: localStorage.getItem("token_pnb"),
      loggedUserID: 0,
      changePasswordDialog: false,
      newPassword: "",
      confirmPassword: "",
      page: 1,

      pageCount: 0,
      role: [],
      paginationLimits: paginationLimits,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      dob_menu: false,
      items: [],
      search: "",
      editedIndex: -1,
      today: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      dob_validation: format(
        parseISO(
          new Date(
            new Date().getFullYear() - 18,
            new Date().getMonth(),
            1
          ).toISOString()
        ),
        "yyyy-MM-dd"
      ),
      editedItem: {
        first_name: "",
        last_name: "",
        dob: format(
          parseISO(
            new Date(
              new Date().getFullYear() - 18,
              new Date().getMonth(),
              1
            ).toISOString()
          ),
          "yyyy-MM-dd"
        ),
        phone: "",
        user_name: "",
        password: "",
        email: "",
        role: [],
        id: 0,
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        dob: format(
          parseISO(
            new Date(
              new Date().getFullYear() - 18,
              new Date().getMonth(),
              1
            ).toISOString()
          ),
          "yyyy-MM-dd"
        ),
        phone: "",
        user_name: "",
        password: "",
        email: "",
        role: [],
        id: 0,
      },
      headers: [
        {
          text: "first name",
          align: "start",
          width: "200",
          sortable: true,
          value: "first_name",
        },
        {
          text: "last name",
          align: "start",
          width: "200",
          sortable: true,
          value: "last_name",
        },
        {
          text: "dob",
          align: "start",
          width: "200",
          sortable: true,
          value: "dob",
        },
        {
          text: "phone",
          align: "start",
          width: "200",
          sortable: true,
          value: "phone",
        },
        {
          text: "email",
          align: "start",
          width: "200",
          sortable: true,
          value: "email",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    let decoded = jwt_decode(this.userToken);
    this.loggedUserID = decoded.user_id;

    this.initialize();
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    validatefrm() {
      return this.$refs.frm.validate();
    },
    reset() {
      return this.$refs.frm.reset();
    },
    validatePasswordfrm() {
      return this.$refs.frm_password.validate();
    },
    resetPassword() {
      return this.$refs.frm_password.reset();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      this.items = await api.get();
      this.role = await api.getRoles();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.dob = this.formatDate(this.editedItem.dob);
      if (this.editedItem.role?.length > 0) {
        this.editedItem.role = this.editedItem.role[0].id;
      }
      this.dialog = true;
    },

    async deleteItem(item) {
      if (this.loggedUserID == item.id) {
        this.showErrorAlert("No Permission to Delete your own Profile.");
      } else {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`User Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validatefrm()) {
        try {
          let req = {
            first_name: this.editedItem.first_name,

            last_name: this.editedItem.last_name,

            dob: this.editedItem.dob,

            phone: this.editedItem.phone,

            user_name: this.editedItem.user_name,

            password: this.editedItem.password,

            role: [this.editedItem.role],

            email: this.editedItem.email,
          };
          let type = "Saved";
          if (this.editedIndex > -1) {
            type = "Updated";
            delete this.editedItem["password"];
            await api.edit(this.editedItem.id, req);
            Object.assign(this.items[this.editedIndex], this.editedItem);
          } else {
            // console.log("REQ", req);
            this.editedItem.role = [];
            let item = await api.create(req);
            this.items.push(item);
          }
          this.showSuccessAlert(`User ${type}.`);
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.close();
        }
      }
    },
    async changePassword() {
      if (this.validatePasswordfrm()) {
        if (this.newPassword == this.confirmPassword) {
          let data = {
            new_password: this.newPassword,
            user: this.editedItem.id,
          };
          await api.changePassword(data);

          this.showSuccessAlert(`Password Updated`);
          this.dialogClose();
        } else {
          this.showErrorAlert("Password Mismatch");
        }
      }
    },
    dialogClose() {
      this.changePasswordDialog = false;
      this.newPassword = "";
      this.confirmPassword = "";
      this.resetPassword();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User  " : "Edit User";
    },

    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 1;
    },
  },
};
</script>
